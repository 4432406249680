import { If, Maybe, Update } from './misc/utils/types'
import { IUser } from './user'

export interface ITest<TAnswer = number, OmitCorrectAnswers extends boolean = false> {
    id: string // random string id
    draft: Maybe<TestDraft>
    title: string
    description: string
    subject: Subjects
    timeMinutes: number
    published: boolean
    approved: boolean
    author: Omit<IUser, 'password' | 'email' | 'passwordResetToken' | 'verifyToken'>
    questions: If<
        OmitCorrectAnswers,
        Omit<IQuestion<TAnswer>, 'correctAnswers'>,
        IQuestion<TAnswer>
    >[]
    totalQuestions: number
    totalCompletions: number
    createdAt: number
    userCompletions: IUserCompletion[]
    lastPublishedChangesAt: number
    lastEditedAt: number
    publishedAt: Maybe<number>
    settings: Maybe<TestSettings>
}

export interface IQuestion<TAnswer = number> {
    id: number // numeric id
    text: string
    type: QuestionType
    answers: string[]
    correctAnswers: TAnswer[]
}

export type ITestWithoutQuestions<
    TAnswer = number,
    OmitCorrectAnswers extends boolean = false
> = Update<ITest<TAnswer, OmitCorrectAnswers>, 'questions', []>


export interface IUserCompletion {

    /**
     * IP of the user that completed the test.
     */
    ip: string

    /**
     * Name of the user that completed the test.
     */
    username: string

    /**
     * Attempts history of the completion.
     */
    attempts: ITestAttempt[]
}

export interface ITestAttempt {

    /**
     * Timestamp of when the user started completing the test.
     */
    startedAt: number

    /**
     * Timestamp of when the user finished completing the test.
     */
    finishedAt: number

    /**
     * Time taken to complete the test (in seconds).
     */
    timeTaken: number

    /**
     * Test completion results.
     */
    results: AnswersComparisonResult
}

export type AnswersComparisonResult = Record<
    'correctAnswers' | 'incorrectAnswers' |
    'correctAnswersPercentage' | 'incorrectAnswersPercentage',
    number
>

export type TestDraft = Pick<
    ITest,
    'title' | 'description' | 'subject' | 'timeMinutes' |
    'questions' | 'totalQuestions' | 'settings'
>

export type TestSettings = Record<
    'displayAnswers' | 'displayCorrectAnswers' | 'randomizeQuestion' |
    'randomizeAnswers' | 'singleAttempt' | 'requireLogin',
    boolean
>

export type TestsSortOrder =
    'creationDate' | 'publishedDate' |
    'lastEditedDate' | 'name' | 'completions'

export enum QuestionType {
    SINGLE_CHOICE = 0,
    MULTIPLE_CHOICE = 1,
    // TEXT = 2,
    // ORDER = 3,
    // MATCH = 4
}

export enum TestEndingReason {
    NONE = 0,
    COMPLETED = 1,
    EXITED = 2,
    TIMEOUT = 3
}

export enum Subjects {
    ENGLISH = 0,
    ALGEBRA = 1,
    BIOLOGY = 2,
    GEOMETRY = 3,
    GEOGRAPHY = 4,
    HISTORY = 5,
    INFORMATICS = 6,
    MATHS = 7,
    SOCIALS = 8,
    RUSSIAN = 9,
    PHYSICS = 10,
    CHEMISTRY = 11,
    SPANISH = 12,
    FRENCH = 14,
    GERMAN = 15,
    LIFE_SAFETY = 16,
    INFO_SAFETY = 17,
    OTHER = 18,
}
